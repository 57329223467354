import React from 'react';

function Trucking() {
  return (
    <div>
      <h1>Trucking Page</h1>
    </div>
  );
}

export default Trucking;
